.footer {
  background-color: #333;
  color: #356398;
  padding: 4rem 2rem;
  font-family: Arial, sans-serif;
  padding-bottom: 1em;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 2rem; */
  }
  
  .footer-main {
    flex: 2;
  }
  
  .footer-columns {
    display: flex;
    gap: 5rem;
  }
  .footer-column h3{
    font-size: 20px;
    color: #eceff4;
  }
  .footer-links h3{
    color: #5071d5;
  }
  .footer-links ul{
    color: #fdfdff;
  }
  .footer-main h2{
    font-size: 20px;
    color: #f9fafd;
  }

  
  .footer-column {
    flex: 2px;
    padding: 5px;
  }

  .footer-links {
    padding: auto;
    flex: 1px;
    display: flex;
    gap: 1rem;
  }
  
  .footer-contact {
    flex: 1;
    text-align:center;
  }
  
  h3, h3 {
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 0.5rem;
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
  
  .contact-button {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #fdfdff;
    padding: 5px;
    cursor: pointer;
    min-width: 150px;
    font-size: 13px;
    margin-bottom: 2em;
  }
  .footer-contact ul{
    color: #f8f3f3;
    padding-top: 15px;
  }
  .phone-number {
    font-size: 1.2rem;
    margin-top: 2rem;
    color: #f8f3f3;
  }
  .social-cstm a{
padding: 6px;
color: #bdbdbd;
  }
  
  .footer-bottom {
    align-items: center;
    padding-top: 1rem;
  }
  
  .footer-logo img {
    height: 31px;
    margin-top: 10px;
    margin-bottom: 20px;
    
    
  }
  
  .footer-legal {
    font-size: 1rem;
    text-align: center;
    padding-bottom: 0px;
  }
  .footer-legal p{
    color: #eceff4;
    /* padding-bottom: 10px; */
    font-size: 13px;
    
  }
  
  .footer-social {
    display: flex;
    gap: 1.5rem;
    margin-left: 1000px;  
    padding-bottom: 1px;
  } 
.footer-social{
    color: #eceff4;
}

.footer-column h3{
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 17px;
}

.footer-column p {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  line-height: 20px;
  font-size: 13px;
  color: #bdb7b7;
}
.mobHide{
  display: block;
}
.mobShow{
  display: none;
}
@media only screen and (max-width: 680px) {
  .mobHide{
    display: none;
  }
  
.mobShow{
  display: flex;
}
.footer {
  padding: 2rem 2rem;
}
}