.daivik{
  padding: 0
}
body{
  background-color: #000;
}

.title {
  font-family: "Roboto Condensed", sans-serif;
}

.topMenu{
  background: #000;
  color: #fff;
}
.menuDesktop{
  display: block;
  text-align: center;
}

.menuDesktop ul{
  padding: 0;
  margin: 0;
}

.menuDesktop li{
  font-family: "Roboto Condensed", sans-serif;
    min-width: 102px;
    list-style: none;
    padding-top: 10px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
}
.bookNowBtn {
  float: right;
  margin-right: 15px;
  margin-top: 5px;
  border: none;
  border-radius: 28px;
  background: #b42122;
  color: #fff;
  padding: 3px 20px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  min-height: 35px;
}
.notification {
  background: #292a2a;
    color: #fff;
    padding-top: 8px;
    margin: 0;
    padding-bottom: 8px;
    line-height: 14px;
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}
.BannerImg{
  width: 100%;
}
.logo {
  width: 135px;
  margin-bottom: 10px;
  padding-top: 4px;
}
.bannerText{
  position: absolute;
  width: 100%;
  top: 15%;
  /* margin-left: 15px; */
  font-family: "Jost", sans-serif;
}
.title {
  font-weight: bold;
  font-size: 7rem;
  color: #fff;
}
.heading{
  font-family: "Roboto Condensed", sans-serif;
  font-size: 64px;
  font-weight: 900;
  color: #fff;
}
.bottomGradient{
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 37%, rgba(255,255,255,0) 100%);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
}
.relative {
  position: relative;
}
.minHtc1{
  height:190px;
  overflow: hidden;
}
.minHtc2{
  height:95px;
  overflow: hidden;
}
.contentBorder{
  /* border:1px solid #282828;
  height:95px; */
}

.redColor{
  color:#b42122;
}
.text1 {
  color: #858585;
  font-weight: 100;
  font-size: 21px;
  padding-top: 3em;
    padding-bottom: 3em;
    font-size: 17px;
}
.modalFullWidth{
  position: fixed;
  width: 100%;
  height: 100vh;
  background: hsl(0deg 0% 0% / 39%);
  z-index: 999999;
  top: 0;
}
.width100{
  width: 100%;
}
.modalContainer{
  position: absolute;
  width:600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
}
.modalHalf{
  width:50%;
  float: left;
}
.formLabel{
  font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    padding: 5px 0;
    margin: 0;
    font-weight: bold;
}
.padding15{
  padding: 15px;
}
.inputStyle {
  width: 100%;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-size: 13px;
  padding: 5px;
}
.submitBtn{
  width: 100%;
    border: 1px solid #b42122;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    font-weight: bold;
    margin-top: 15px;
    background: #b42122;
    color: #fff
}
.modalTitle{
  font-family: "Roboto Condensed", sans-serif;
    font-size: 17px;
    padding: 5px 0;
    margin: 0;
    font-weight: bold;
}
.desFont{
  font-size: 13px;
  line-height: inherit;
}
.thanksForm {
  position: absolute;
    background: #d4ffe2;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    min-height: 90px;
    padding: 10px;
    font-family: "Roboto Condensed", sans-serif;
}
.footerLinks ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
.footerLinks li{
  color: #8c8c8c;
}
.footerLinks h2{
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
}
.regPedding{
  padding-bottom: 5em;
}
.footerBottom{
  background-color: #151515;
    min-height: 40px;
}
.footerBottom p{
  padding-top: 4px;
    margin: 0;
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
  color: #fff;
}
.cityImg{
  position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.menuTop {
  position: relative;
  padding-bottom: 8%;
}
.bookNowBtnStickey{
  position: fixed;
    bottom: calc(50% - 20px);
    z-index: 99999;
    right: -59px;
    border: none;
    border-radius: 0;
    background: #b42122;
    color: #ffffff;
    padding: 3px 20px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    transform: rotate(-90deg);
    width: 145px;
    text-align: center;
}
button:focus {
  outline: none;
  outline: none;
}
.padding-topCstm1{
  padding-top: 4em;
  margin-bottom: 5em;
}
.specifications{
  list-style: none;
  padding: 0;
}
.specifications li {
  list-style: none;
  color: #fff;
  line-height: 34px;
}
.bookNowBtnHome {
  /* float: right; */
  margin-right: 15px;
  margin-top: 10px;
  border: none;
  border-radius: 28px;
  background: #b42122;
  color: #fff;
  padding: 3px 20px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.pageTitle{
  font-size: 30px;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
}

.pageData{
  color: #858585;
}

.footerLinks li a{
  color: #858585;
  text-decoration: none!important;
}
.headerWave {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
.menuActive{
  background-color: #000000;
}
.bannerTyre{
  background: url(./Assets/banner4.jpg);
  background-size: cover;
  padding-top: 4em;
}
.bannerTyreStark{
  background: url(./asset/images/banner5.jpg);
  background-size: cover;
}
.boxCont{
  background: rgb(26,26,26);
background: linear-gradient(336deg, rgba(26,26,26,1) 0%, rgba(12,11,14,1) 100%);
border: 2px solid #1a1a1a;
    border-radius: 6px;
    min-height: 235px;
    padding: 15px;
    color: #fff;
    margin-bottom: 30px;
}
.boxCont h1{
  font-size: 21px;
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  text-transform: uppercase;

}
.boxCont p{
  color: #858585;
  font-size: 15px;
}
.width50{
  width: 50%;
}
video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top:0
}
.FloatImg{
  width: 130px;
    position: absolute;
    top: 50%;
    left: 79%;
    transform: translate(-50%, -50%);
}
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

.content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white;
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }

    .content {
        height: 30vh;
    }

    h1 {
        font-size: 24px;
    }
}

.menuDesktop a{
  text-decoration: none;
  color: #fff;
  font-weight: 400;
}
.borderImg1{
  border-radius: 10px;
}
.borderImg2{
  border-radius: 0 10px 10px 0;
}
.lowHeading{
  font-size: 36px;
}
.menu-bar{
  z-index: 9999;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
}
.responsive-map{
  overflow: hidden;
  padding-bottom:60%;
  position:relative;
  height:0;
  }
  .responsive-map iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
  }
.colorWhite{
  color:#fff;
}
.mob15{
  padding-top: 15px;
}






.closeBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 23px;
  height: 23px;
  background: #b42122;
  text-align: center;
  color: #fff;
  cursor:pointer;
}
.mobHide{
  display: block;
}
.mobShow{
  display: none;
}
@media only screen and (max-width: 680px) {
  .modalContainer {
    position: absolute;
        width: 90%;
        top: 5vh;
        left: 5%;
        transform: translate(0%, 0%);
        background: #fff;
        height: auto;
}
  .mobHide{
    display: none;
  }
  
.mobShow{
  display: flex;
}
  .modalHalf{
    width: 100%;
  }
  .navbar {
    padding: 0 7px;
}
.title {
  font-weight: bold;
  font-size: 2rem;
}
.heading {
  font-size: 34px;
}
.mobComPadd{
  padding-left: 15px;
  padding-right: 15px;
}
.text1 {
  color: #858585;
  font-weight: 100;
  font-size: 16px;
  padding-top: 1em;
  padding-bottom: 1em;
}
.col-sm-6{
  width: 50%;
}
.footerLinks h2 {
  font-size: 23px;
}
.mobPad20{
  margin-bottom: 2em;
}
.mbMarginBtm{
  margin-bottom: 15px;
}
}




















p{
  font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    line-height: 30px;
    font-size: 17px;
}

.top_banner_home{
  background-image: url(./asset/images/page1-img_01.jpg);
  background-size: cover;
  background-position: 71%;
}
.top_banner_sc1{
    background-image: url(./asset/images/page1-img_10.jpg);
    background-size: cover;
    background-position: 71%;
}
.top_banner_sc2{
    background-image: url(./asset/images/page1-img_11.jpg);
    background-size: cover;
    background-position: 71%;
}
.brand_name {
  font-family: "Six Caps", sans-serif;
  font-size: 160px;
  line-height: 175px;
  color: #1a1a1a;
  letter-spacing: 18px;
}
.brand_slogan {
  font-family: "Oswald", sans-serif;
  font-size: 46px;
  line-height: 40px;
  color: #303030;
  font-weight: 100;
  margin-top: 15px;
  display: block;
  letter-spacing: 4.5px;
}
.brand_tag{
  font-family: "Oswald", sans-serif;
  font-size: 36px;
  text-transform: uppercase;
}
.brand_des{
  color: #4d4d4d;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 1px;
    font-family: "Oswald", sans-serif;
}
.shadow_input {
  display: block;
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  font-size: 18px;
  padding: 17.5px 28px;
  line-height: 28px;
  color: #1a1a1a;
  background: #f3f3f3;
  border: 1px solid #c0c0c0;
  border-radius: 1px;
  box-shadow: inset 4px 4px 3px rgba(0, 0, 0, 0.07);
}
.btn-lg {
  border-width: 2px;
  padding: 18px 26px;
  font-size: 20px;
}
.btn-primary {
  color: #1a1a1a;
  background: #ffe51b;
  border-color: #ffe51b;
}
.btn-primary:hover, .btn-primary:active {
  background: #f1d600;
  border-color: #f1d600;
  color: #1a1a1a;
}
.banner_Btn{
  margin-top: 20px;
  border-radius: 0;
}
.banner_text_container{
  padding-top: 12em;
  padding-bottom: 9em;
}
.padCsBox{
  padding-top: 6em;
  padding-bottom: 1em;
}
.bg-color-1 {
  background: #f6f5f5;
}
.bgWhite{
  background: #fff;
}
.fontWhite{
  color: #fff;
}
.well-xl {
  padding-top: 1.85rem;
  padding-bottom: 1.5rem;
}
.paddCN{
  padding-top: 4em;
  padding-bottom: 4em;
}
.counter {
  font-size: 150px;
  color: #1a1a1a;
    font-family: "Six Caps", sans-serif;
    /* position: absolute; */
    left: 51%;
    font-weight: 150;
    transform: translateX(-50%);
    top: -188px;
    letter-spacing: 15px;
}
.number_section {
  position: relative;
  z-index: 2;
  background: #f6f5f5;
  padding: 0px 0 33px 0;
  /* margin-bottom: 9em; */
}
.counterCount1:before {
  content: "01";
  counter-increment: section;
}
.counterCount2:before {
  content: "02";
  counter-increment: section;
}
.counterCount3:before {
  content: "01";
  counter-increment: section;
}
.orangeColor{
  color:#F58434!important
}
.heading-4 {
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 2.5px;
    color: #5c5c5c;
    font-family: "Oswald", sans-serif;
}
.heading-3 {
  font-size: 39px;
        line-height: 38px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.8px;
  position: relative;
  font-family: "Oswald", sans-serif;
}
.padding-bottom-mx{
  padding-top: 3em;
  padding-bottom: 3em;
}
.fullWidth{
  width: 100%;
}
.banner_Float_div{
  position: absolute;
  background-color: #fff;
  top: 0;
  width: 30%;
  padding: 5em 20px;
}
.links_cs a{
  text-decoration: none;
  color: #fff;
}

.btm3{
  padding-bottom: 3em;
  padding-top: 3em;
}
.btt4 {
  padding-top: 4em;
}
.innerPage_Cont {
  padding-top: 1em;
  padding-bottom: 9em;
}







@media only screen and (max-width: 680px) {
.padding-topCstm1 {
  margin-bottom: 1em;
  padding-top: 1em;
}
.brand_name {
  font-size: 78px;
  line-height: 110px;
  letter-spacing: 18px;
}
.paddCN {
  /* padding-top: 0; */
}
.counter {
  font-size: 78px;
}
}